<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/message/screens' }"
      >首页
      </el-breadcrumb-item
      >
      <el-breadcrumb-item>影厅列表</el-breadcrumb-item>
      <el-breadcrumb-item>售出详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 底部卡片 -->
    <div>

      <el-card>
        <div style="font-weight: bold; font-size: 20px;">
          全部座位售出情况
          <span style="font-size: 16px; margin-left: 10px">
              已出售：{{ showData.已售出座位数 }} / 未出售：
              {{ showData.可用座位数 - showData.已售出座位数 }} / 不可用：{{
              showData.不可用座位数
            }}
            </span>
        </div>
        <div id="tip">
          <div>
            <span style="font-weight: bold">提示:</span> 白色座位为：可选座位 |
            红色座位为：已损坏座位 | 绿色座位为：已锁定/已售出座位 | 蓝色座位为:当前选中座位
          </div>
        </div>
      </el-card>
      <el-row :gutter="24" style="margin-top:10px" :offset="5">
        <el-col :span="4">
          <el-card :body-style="{'padding':'5px 10px','user-select': 'none'}">
            <div>
              <p>演出日期：</p>
              <el-date-picker
                  type="date"
                  :clearable="false"
                  placeholder="请选择日期"
                  v-model="selectedDate"
                  @change="getSessionList()"
                  style="width:100%"
              ></el-date-picker>
            </div>
            <div>
              <el-select
                  v-model="selectedValue"
                  placeholder="请选择场次"
                  @change="handleClick"
                  style="width:100%"
              >
                <el-option
                    v-for="(item, index) in sessionList"
                    :key="index"
                    :label="item.放映影片.名称 + '：' + item.开始时间"
                    :value="item.ID"
                >
                </el-option>
              </el-select>
            </div>
            <div style="margin-top:10px">
              <p>出票渠道：</p>
              <el-select
                  v-model="qudaoType"
                  filterable
                  placeholder="请选择出票渠道类型"
                  @change="clickChannel"
                  clearable
                  @clear="clearQudaoType"
                  style="width:100%"
              >
                <el-option
                    v-for="(item, index) in qudaoTypeList"
                    :key="index"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-select
                  filterable
                  v-model="qudao"
                  placeholder="请选择出票渠道"
                  @change="clickChannelUser"
                  style="width:100%"
              >
                <el-option
                    v-for="(item, index) in qudaoList"
                    :key="index"
                    :label="item.name"
                    :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-checkbox v-if="qudaoType" v-model="seatAlign" label="座位对齐" border style="margin-top: 10px" @change="handleClick(areaName)"></el-checkbox>

          </el-card>

        </el-col>
        <el-col v-if="qudaoType" :span="16">
          <div class="site-box">

            <div class="site-box-title">
              <el-button
                  :type="canSelectLock?'warning':'primary'"
                  size="small"
                  style="margin: 0 5px"
                  @click="changeCanLock()"
              >{{ canSelectLock ? '取消解锁' : '进行解锁' }}
              </el-button>
              <div
                  :class="[{ 'select-areaname': item.areaName == areaName }]"
                  v-for="(item, i) in areaList"
                  :key="i"
                  @click="handleClick(item.areaName)"
              >
                {{ item.areaName }}区
              </div>
            </div>
            <div class="site-box-content">
              <div class="seatarea noselectdefult" ref="container" @mousedown="startSelection"
                   @mousemove="updateSelection" @mouseup="endSelection">
                <!-- 行号 -->
                <div
                    ref="sitebox"
                    v-for="(item, i) in seatList"
                    class="row"
                    :key="i"
                >
                  <div class="sitleft">
                    {{ item.rowNum }}
                  </div>
                  <!-- 座位 -->
                  <div
                      ref="sititem"
                      :data-id="item.rowNum + '' + j"
                      :class="[
                    'sit',
                    { 'bg-cantsit': item1.状态 == 2 },
                    { 'bg-sit': item1.状态 == 1 },
                    { 'bg-selecting': item1.状态 == 9 },
                    { 'bg-sit-lock': item1.状态 == 4 },
                    { 'bg-sit-selled': item1.状态 == 5 },
                  ]"
                      v-for="(item1, j) in item.seatList"
                      :key="j"
                  >
                    <div v-if="item1['名称']" class="seat-num">
                      {{ item1["名称"] | formatSeat }}
                    </div>
                    <div v-else class="seat-num" style="opacity: 0">
                      {{ 0 }}
                    </div>
                  </div>
                </div>
                <!--              <div v-if="selecting" ref="selectionBox" :style="selectionBoxStyle"></div>-->
                <div v-if="selecting" ref="selectionBox" :style="selectionBoxStyle"></div>

              </div>
            </div>

          </div>
        </el-col>
        <el-col v-if="(qudaoType&&selectedSeatList.length)||canSelectLock" :span="4">
          <el-card :body-style="{'padding':'5px 10px'}">
            <div v-if="!canSelectLock">
              <div class="ticket-title">
                <div>
                  <span>{{ areaName }}区：</span>
                  <span>{{ selectedSeatList.length }}座</span>
                </div>
              </div>
              <div class="ticket-item">
                <div v-if="filterTicket.length === 0">该区域暂无票种信息</div>
                <div
                    :class="{ activeTicket: item.ID === activeTicketData.ID }"
                    @click="selectTicketType(item)"
                    v-else
                    v-for="(item, index) in filterTicket.filter(v=>selectedSeatList.length%v.seat_number===0)"
                    :key="index"
                >
                  <div style="display:flex;justify-content:space-between">
                    <div>价格：<span style="font-weight: bold">{{ item.price }}</span></div>
                    <div><i class="el-icon-user-solid"></i>x{{ item.seat_number }}</div>
                  </div>
                  <div>票名：{{ item.name }}</div>

                </div>
              </div>
              <div v-if="selectedSeatList.length&&activeTicketData.ID" class="total-price">
                <div>
                  <span>合计：</span>
                  <!-- <span style="font-size: 14px; text-decoration: line-through;">￥{{ totalMoney }}元</span> -->
                  <span style="font-size: 18px"
                  >￥{{
                      (activeTicketData.price * (selectedSeatList.length / (activeTicketData.seat_number || 1))).toFixed(2)
                    }}</span
                  >
                </div>

              </div>
              <div v-if="selectedSeatList.length&&activeTicketData.ID">
                <el-divider class="myel-divider"></el-divider>
                <el-radio-group v-model="orderfrom" class="myradio">
                  <el-radio
                      v-for="(item, index) in orderFromOptions"
                      :key="index"
                      :label="item.name"
                      :value="item.type"
                  >
                  </el-radio>
                </el-radio-group>
                <el-divider class="myel-divider"></el-divider>
                <span>备注：</span>
                <el-input v-model="note"></el-input>
              </div>
              <div>
                <el-button v-if="selectedSeatList.length&&activeTicketData.ID" type="primary" @click="lockSeat"
                           style="margin: 5px">确认出票
                </el-button>
                <el-button
                    style="margin: 5px"
                    type="success"
                    @click="lockSeatHttp(1)"
                >确认锁座
                </el-button>
              </div>

            </div>
            <div v-else>
              <div class="ticket-title">
                <div>
                  <span>{{ areaName }}区：</span>
                  <span>{{ selectedSeatList.length }}座</span>
                </div>
              </div>
              <el-button
                  type="primary"
                  v-if="canSelectLock"
                  @click="lockSeatHttp(2)"
              >解锁座位
              </el-button>

            </div>
          </el-card>

        </el-col>

      </el-row>
    </div>


  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";

import {ticketByid, qudaoByid, qudaoTypeList, qudaoList} from "@/network/channel.js";

export default {
  name: "detalied",
  data() {
    return {
      selecting: false,
      canSelectLock: false,
      seatAlign: false,
      note:'',
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      inputHang: "",
      qudaoTypeData: [], //渠道类型数据
      qudaoTypeList: [], //渠道类型过滤
      qudaoList: [], //渠道列表
      ticket: [], //票种信息
      filterTicket: [], //票种过滤
      qudaoType: "", //渠道类型
      qudao: "", //渠道
      activeQudaoType: [{name: ""}], //选中的渠道类型
      activeChangci: "", //选择的场次时间
      activeTicketData: {}, //选择的票种数据
      // ticketNum: 3,//当前票种的座位数
      tipSelect: "请选择渠道类型",
      activeTicketNum: 1, //票数量
      activeTicketNumNew: 1, //票数量
      activeSeatDom: [], //选的座位的dom

      companyId: window.sessionStorage.getItem("companyId"),
      companyName: window.sessionStorage.getItem("companyName"),
      userId: window.sessionStorage.getItem("userId"),
      id: this.$route.query.id || '0001-0001-0001-0001-0001-0001',
      showData: {},
      seatList: [], //座位信息
      oldSeatList: [], //座位信息原数据
      areaList: [],
      sessionList: [],
      areaName: "",
      selectedDate: new Date(),
      selectedValue: "",
      selectAreaName: "",
      selectedSession: {
        放映影片: {
          名称: "",
        },
      },
      // 选中的座位
      selectedSeatList: [],
      totalMoney: 0,
      movieInfo: {},
      orderfrom: "", //支付方式
      orderFromOptions: [
        {
          name: "支付宝",
          type: 4,
        },
        {
          name: "OTA兑换",
          type: 6,
        },
        {
          name: "VM",
          type: 7,
        },
        {
          name: "对公汇款",
          type: 8,
        },
        {
          name: "欠款",
          type: 9,
        },
        {
          name: "微信码",
          type: 3,
        },
        {
          name: "现款",
          type: 2,
        },
      ],
      fromOrderNo: "",
      addDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
      },
      isExceed: false,

    };
  },
  created() {
    console.log("companyName", this.companyName);
    this.getSessionList();
    this.getChannel();
    this.getAllQudaoList()
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    // 重置渠道类型
    clearQudaoType() {
      window.location.reload()
    },
    // 获取所有渠道
    async getAllQudaoList() {
      let res = await qudaoList()
      console.log('res: ', res);
      if (res.data) {
        this.qudaoList = res.data
      }
    },

    // 选票种
    selectTicketType(item) {
      // this.selectedSeatList = [];
      // this.activeTicketData = {};
      this.activeTicketData = item;

      console.log(item)
      this.tipSelect = "";
    },
    // 获取渠道类别信息
    async getChannel() {
      let qudao = await qudaoTypeList();
      if (qudao.data && qudao.data.length > 0) {
        this.qudaoTypeData = qudao.data;
      }
      this.qudaoTypeList = this.qudaoTypeData.map((item) => {
        return item.name;
      });
    },
    // 选渠道类型
    async clickChannel(tag) {
      this.qudaoType = tag;
      this.qudao = '';
      this.qudaoList = []
      if (this.qudaoType) {
        this.activeQudaoType = this.qudaoTypeData.filter(
            (item) => item.name == this.qudaoType
        );
        console.log("this.activeQudaoType: ", this.activeQudaoType[0].ID);
        this.tipSelect = "请选择票种";
        this.getTicketList();
        this.getQudaoList();

        this.selectedSeatList = [];
        this.activeTicketData = {};
      }
    },
    // 选择渠道
    async clickChannelUser(tag) {
      console.log("tag: ", tag);
      this.qudao = tag.name;
      this.qudaoType = tag.typeName;
      this.activeQudaoType = this.qudaoTypeData.filter((item) =>
          item.name == this.qudaoType
      );
      console.log('this.activeQudaoType: ', this.activeQudaoType);
      this.getTicketList()
    },
    // 获取出票渠道
    async getQudaoList() {
      let res = await qudaoByid({
        id: this.activeQudaoType[0].ID,
      });

      if (res.data && res.data.length > 0) {
        this.qudaoList = res.data;
      }
      console.log("getTicketList==res: ", res);
    },
    // 获取票价
    async getTicketList() {
      this.ticket = [];
      this.filterTicket = [];
      let ticketres = await ticketByid({
        teamId: this.activeQudaoType[0].ID,
      });
      console.log("ticketres: ", ticketres);
      if (ticketres.data && ticketres.data.length > 0) {
        this.ticket = ticketres.data;
        this.filterTicketFn();
      }
    },
    // 筛选票种
    filterTicketFn() {
      console.log("this.areaName: ", this.areaName);
      if (this.areaName) {
        this.filterTicket = this.ticket.filter(
            (item) => item.areaName.charAt(0) == this.areaName.charAt(0)
        );
      }
    },

    // 查找影厅信息
    //查找座位信息
    showInFoById(loading) {
      this.$axios({
        url: `/manager/hallDetail?id=${this.id}&selectedSession=${this.selectedSession.ID}`,
      })
          .then((res) => {
            console.log("showInFoByIdres: ", res);
            this.showData = res.data;
            //座位列表
            this.areaList = res.data.区域;
            this.areaName = this.areaList[0].areaName;
            this.handleClick(this.areaName);
            loading.close();
          })
          .catch((err) => err);
    },
    getSessionList() {
      let date = new DateFormat(this.selectedDate).toString(
          "yyyy-0m-0dT08:00:00"
      );
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios({
        url: `/manager/sessionList?currentPage=1&pageSize=10&status=4&selectedDate=${date}`,
      })
          .then((res) => {
            this.sessionList = res.data;
            //座位列表
            if (this.sessionList && this.sessionList.length > 0) {
              console.log("this.sessionList: ", this.sessionList);
              this.selectedValue = this.sessionList[0].ID;
              this.selectedSession = this.sessionList[0]
              this.showInFoById(loading);
            } else {
              loading.close();
              this.$message({
                type: "error",
                message: "暂无票务信息!",
              });
            }
          })
          .catch((err) => {
            loading.close();
            console.log(err);
          });
    },
    getSeatList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(' this.areaName: ', this.areaName);
      this.$axios({
        url:
            "/show/sessionDetail?场次id=" +
            this.selectedSession.ID +
            "&区域名=" +
            this.areaName,
      })
          .then((res) => {
            this.seatList = res.data.区域[0].座位信息;
            this.oldSeatList = res.data.区域[0].座位信息;
            console.log("seatList: ", this.seatList);
            this.movieInfo = res.data.放映影片;
            console.log("movieInfo", this.movieInfo);

            let combinedArray = []
            this.seatList.map((item) => {
              item.seatList = this.doSort(item.seatList, res.data.区域[0]['sort'])
              combinedArray = combinedArray.concat(item.seatList)
              return item
            });

            if (this.seatAlign){
              let longestArray =this.doSort(combinedArray.reduce((acc, curr) => {
                const x = acc.find(item => item.列号 === curr.列号);
                if (!x) {
                  return acc.concat([curr]);
                } else {
                  return acc;
                }
              }, []),res.data.区域[0]['sort'])
              for (let item of this.seatList) {
                for (let i = 0; i < longestArray.length; i++) {
                  let found = item.seatList.find(obj => obj.列号 === longestArray[i].列号);
                  if (!found) {
                    item.seatList.splice(i, 0, {列号: longestArray[i].列号, 名称: ''});
                  }
                }
              }
            }


            loading.close();
          })
          .catch((err) => {
            loading.close();
            console.log(err);
          });
    },
    doSort(array, sort='asc') {
      if (sort === 'desc') {
        array.sort((a, b) => b.列号 - a.列号);
      } else if (sort === 'ldra') {
        let oddArr = array.filter(num => num.列号 % 2 !== 0);
        let evenArr = array.filter(num => num.列号 % 2 === 0);
        oddArr.sort((a, b) => b.列号 - a.列号);
        evenArr.sort((a, b) => a.列号 - b.列号);
        array = [...oddArr, ...evenArr]
      } else {
        array.sort((a, b) => a.列号 - b.列号);
      }
      return array
    },
    // 切换区域
    handleClick(flag) {
      this.areaName = flag;
      this.filterTicketFn();
      console.log("flag.label: ", flag.label);

      console.log("flag: ", flag);
      this.activeTicketData = {}
      this.seatList = [];
      this.selectedSeatList = [];
      this.totalMoney = 0;
      let that = this;
      this.selectedSession = this.sessionList.filter(
          (s) => s.ID == that.selectedValue
      )[0];
      this.sessionList.map((s, i) => {
        if (s.ID == that.selectedValue) {
          this.selectAreaName = i;
        }
      });
      console.log("this.selectedSession: ", this.selectedSession);

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.getSeatList();
      if (!flag) {
        this.showInFoById(loading);
      }
    },


    // 确认兑票
    async lockSeat() {
      if (!this.orderfrom) {
        this.$message({
          type: "error",
          message: "请选择支付类型!",
        });
        return;
      }
      this.activeChangci = this.formatChangciTime(
          this.selectedSession.开始时间
      );

      let that = this;
      that.genrateOrder(that);
      // that.$confirm("请确认已完成线下收款或三方平台票务核销", "确认兑票", {
      //   confirmButtonText: "确认",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //     .then(() => {
      //       console.log("用户点击确认");
      //
      //     })
      //     .catch(() => {
      //       console.log("用户点击取消");
      //     });
    },
    genrateOrder(that) {
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 影片名
      // 原价
      // 影片id
      // 数量
      // 座位id数组
      // 场次id
      // 区域名
      let seatIds = that.selectedSeatList.map((o) => o.ID);
      let goods = [
        {
          title: that.selectedSession.放映影片.名称,
          money: (that.activeTicketData.price * (that.selectedSeatList.length / (that.activeTicketData.seat_number || 1))).toFixed(2),
          id: that.selectedSession.放映影片ID,
          count: that.selectedSeatList.length,
          sceneryAreaId: that.companyId,
          sceneryAreaName: that.companyName, //景德镇记忆
          orderSeat: {
            sessionId: that.selectedSession.ID,
            areaName: this.areaName,
            seatIds,
          },
        },
      ];

      let data = {
        goods,
        address: "",
        // money: that.totalMoney,
        money: (that.activeTicketData.price * (that.selectedSeatList.length / (that.activeTicketData.seat_number || 1))).toFixed(2),
        name: that.selectedSession.放映影片.名称,
        type: 20,
        sceneryAreaId: that.companyId,
        img: that.movieInfo.封面照片,
        userid: that.userId,
        orderType: that.orderfrom, //that.orderfrom == 'PC端商户' ? '1' : '2',
        paystatus: false,
        useDate: that.selectedDate,
        orderfrom: that.orderfrom,
        fromOrderNo: that.fromOrderNo,
        qudao_type: this.activeQudaoType[0].name, //渠道类型
        qudao: this.qudao ? this.qudao : this.activeQudaoType[0].name, //渠道
        changci: this.activeChangci, //场次
        ticketID: that.activeTicketData.ID, //票id
        ticketName: that.activeTicketData.name, //票名
        是否取票: "1",
        note:this.note,
        number:(that.selectedSeatList.length / (that.activeTicketData.seat_number || 1))
      };
      console.log("data: ", data);

      that
          .$axios({
            url: `/order/genrate`,
            method: "POST",
            data,
          })
          .then((res) => {
            console.log(res);
            loading.close();
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: "兑票成功!",
              });
              that.addDialogVisible = false;
              let selectedSeatList=this.selectedSeatList
              if (res.data.订单座位){
                selectedSeatList=selectedSeatList.map(v=>{
                  v.ID=res.data.订单座位.find(s=> s.影厅座位ID==v.ID).ID
                  return v
                })
              }
              that.printTicket(
                  selectedSeatList,
                  res.data.订单号,
                  res.data.ID,
                  this.activeTicketData.name
              );
              that.handleClick(this.areaName);
            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);

            loading.close();
          });
    },
    lockSeatHttp(type) {
      let seatIds = "";
      this.selectedSeatList.forEach((o) => {
        seatIds += o.ID + ",";
      });
      let sessionId = this.selectedSession.ID;

      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 锁定座位
      const data = {
        seatIds,
        sessionId,
        type,
      };
      that
          .$axios({
            url: `/show/serverLockSeat`,
            method: "PUT",
            data,
          })
          .then((res) => {
            console.log(res);
            loading.close();
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: `座位${type == "1" ? "锁定" : "解锁"}成功!`,
              });
              that.handleClick(this.areaName)

            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);

            loading.close();
          });
    },
    printTicket(selectedSeatList, orderNum, orderId, ticketType) {
      console.log(
          "selectedSeatList, orderNum, orderId, ticketType: ",
          selectedSeatList,
          orderNum,
          orderId,
          ticketType
      );
      let that = this;
      let date = this.selectedSession.开始时间.split(" ");
      console.log("开始打印票据", orderNum, date);
      selectedSeatList.forEach((o, index) => {
        o.qrUrl = that.genrateERCodeURL(orderId);
        console.log(
            "that.activeTicketData.price: ",
            that.activeTicketData.price
        );
        selectedSeatList[index].价格信息[0].原价 = that.activeTicketData.price;
        console.log(
            " selectedSeatList[index].价格信息[0].原价: ",
            selectedSeatList[index].价格信息[0].原价
        );
      });
      console.log(
          "selectedSeatList: ",
          selectedSeatList,
          date,
          that.movieInfo.总时长,
          orderNum
      );
      that.$axios({
        method: "post",
        url: "/order/getTicket",
        data: {
          memberid:window.sessionStorage.getItem("userId"),
          orderid:orderNum,
          auditID:window.sessionStorage.getItem("userId"),
          auditName:window.sessionStorage.getItem("userName")
        },
      }).then(()=>{
        that.$router.push({
          path: "/message/ticket",
          query: {
            printInfo: selectedSeatList,
            date,
            time: this.movieInfo.总时长,
            orderNum,
            ticketType,
          },
        });
      })

    },
    genrateERCodeURL(orderId) {
      return `https://test-pyssp.iat.wang/newminipro?type=check&ID=${orderId}&t=${new Date().getTime()}`;
    },

    // 格式化时间
    formatChangciTime(inputDateTime) {
      const regex = /(\d{4})年(\d{2})月(\d{2})日 (\d{2})点(\d{2})分/;
      const match = inputDateTime.match(regex);
      console.log("match: ", match);
      if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];
        const hours = match[4];
        const minutes = match[5];

        // console.log(`年: ${year}, 月: ${month}, 日: ${day}, 时: ${hours}, 分: ${minutes}`);
        return `${year}/${month}/${day} ${hours}:${minutes}`;
      } else {
        console.log("未找到匹配的日期时间格式");
      }
    },
    startSelection(event) {
      this.selecting = true;
      const containerRect = this.$refs.container.getBoundingClientRect();
      const containerScrollTop = this.$refs.container.scrollTop;
      const containerScrollLeft = this.$refs.container.scrollLeft;
      this.startX = event.clientX - containerRect.left + containerScrollLeft;
      this.startY = event.clientY - containerRect.top + containerScrollTop;
      this.endX = event.clientX - containerRect.left + containerScrollLeft;
      this.endY = event.clientY - containerRect.top + containerScrollTop;
    },
    updateSelection(event) {
      if (this.selecting) {
        const containerRect = this.$refs.container.getBoundingClientRect();
        const containerScrollTop = this.$refs.container.scrollTop;
        const containerScrollLeft = this.$refs.container.scrollLeft;
        this.endX = event.clientX - containerRect.left + containerScrollLeft;
        this.endY = event.clientY - containerRect.top + containerScrollTop;
      }
    },
    endSelection() {
      if (this.selecting) {
        const containerRect = this.$refs.container.getBoundingClientRect();
        const containerScrollTop = this.$refs.container.scrollTop;
        const containerScrollLeft = this.$refs.container.scrollLeft;
        let startX = this.startX + containerRect.left - containerScrollLeft;
        let startY = this.startY + containerRect.top - containerScrollTop;
        let endX = this.endX + containerRect.left - containerScrollLeft;
        let endY = this.endY + containerRect.top - containerScrollTop;
        this.selecting = false;
        const items = this.seatList;
        let this_selected_seat = []
        for (let i = 0; i < items.length; i++) {
          for (let j = 0; j < items[i].seatList.length; j++) {
            const item = items[i].seatList[j];
            if (!item.名称) continue
            const itemRect = this.$refs.container.children[i].children[j + 1].getBoundingClientRect();
            let isselected = (
                itemRect.left < Math.max(startX, endX) &&
                itemRect.right > Math.min(startX, endX) &&
                itemRect.top < Math.max(startY, endY) &&
                itemRect.bottom > Math.min(startY, endY)
            )
            if ([this.canSelectLock ? '4' : '1', '9'].includes(item.状态) && isselected) {
              this_selected_seat.push(item)
            }
          }
        }
        console.log(this_selected_seat, 'this_selected_seat')
        for (let item of this_selected_seat) {
          this.toggleElementInSet(this.selectedSeatList, item)
        }
        // *************************
      }
    },
    toggleElementInSet(array, object) {
      const index = array.findIndex(item => item.ID === object.ID);
      if (index !== -1) {
        object.状态 = object.init_type || '1'
        array.splice(index, 1);
      } else {
        object.init_type = object.状态
        object.状态 = '9'
        array.push(object);
      }
    },
    changeCanLock() {
      this.canSelectLock = !this.canSelectLock
      this.resetSeat()
    },
    resetSeat() {
      for (let item of this.selectedSeatList) {
        item.状态 = item.init_type || item.状态
      }
      this.selectedSeatList = []
    }
  },
  computed: {
    selectionBoxStyle() {
      return {
        position: 'absolute',
        left: Math.min(this.startX, this.endX) + 'px',
        top: Math.min(this.startY, this.endY) + 'px',
        width: Math.abs(this.endX - this.startX) + 'px',
        height: Math.abs(this.endY - this.startY) + 'px',
        border: '1px dashed #000',
        backgroundColor: 'rgba(0, 0, 255, 0.1)'
      };
    }
  },
  filters: {
    formatSeat(str) {
      if (str.length > 0) {
        const num = str.match(/\d+/g)[1];
        return num;
      }
      return str;
    },
  },
};
</script>

<style scoped>
hr {
  margin: 20px 5px;
}

.headcard {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}

.setstatus {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.setstatus span {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.seat-status {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-around;
}

.seat-status > div {
  display: flex;
  align-items: center;
}

.total-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  border-top: 1px solid gainsboro;
  padding-top: 5px
}

.total-price > :nth-of-type(1) {
  width: 200px;
  text-wrap: nowrap;
  font-size: 16px;
}

#green {
  background: url("../../assets/img/selected.png") center center no-repeat;
  background-size: 100% 100%;
}

#red {
  background: url("../../assets/img/bought.png") center center no-repeat;
  background-size: 100% 100%;
}

#grey {
  background: url("../../assets/img/unselected.png") center center no-repeat;
  background-size: 100% 100%;
}

.seatarea {
  /* float: left; */
  margin: 20px;
  /* width: 2000px; */
  height: 65vh;
  overflow: auto;
  white-space: nowrap;
  padding: 20px 0 10px 0;
  position: relative;
  border: 1px solid #d1d1d1;
}

.sit {
  display: inline-block;
  height: 28px;
  width: 28px;
  min-height: 10px;
  min-width: 10px;
  margin: 2px 1px;
  background-size: cover;
//position: static;
}

.sitleft {
  display: inline-block;
  margin: 4px 4px 4px 4px;
  /* line-height: 20px; */
  position: relative;
  width: 20px;
  /* height: 20px; */
  top: 10px;
  text-align: center;
}

/* 可选座位 */
.bg-sit {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/S1.png") center center no-repeat; background-size: 100% 100%;
}

/* 已锁（预售）座位 */
.bg-sit-lock {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/lock.png") center center no-repeat; background-size: 100% 100%;
}

.bg-sit-selled {
//background: url("../../assets/img/selected.png") center center no-repeat; background: url("../../assets/img/S3.png") center center no-repeat; background-size: 100% 100%;
}

.bg-sit:hover {
  cursor: pointer;
}

/* 损坏座位 */
.bg-cantsit {
//background: url("../../assets/img/bought.png") center center no-repeat; background: url("../../assets/img/S2.png") center center no-repeat; background-size: 100% 100%;
}

/* 无座 */
.bg-selecting {
//background: url("../../assets/img/unselected.png") center center no-repeat; background: url("../../assets/img/selecting.png") center center no-repeat; background-size: 100% 100%;
}

.row {
  /* 清除格式并换行 */
  height: 50px;
  clear: both;
}

#tip {
  color: #8c939d;
  margin-top: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20%;
  align-items: center;
}

.el-tag {
  margin-left: 10px;
  margin-top: 10px;
}

.seat-num {
  position: relative;
  top: 28px;
  color: #0e0e0e;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
}

:deep .el-tabs__content {
  overflow: auto;
}

/* 票类 */
.ticket-title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}

.ticket-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  /* border: 1px solid red; */
  justify-content: space-between;
}

.ticket-item > div {
  width: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  flex-direction: column;
  margin-bottom: 1px;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid gainsboro;
}

:deep .el-date-editor--date {
  /* width: 180px; */
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.activeTicket {
  background-color: rgb(217, 236, 255);
}


.site-box {
  display: flex;
  flex-direction: column;
}

.site-box-title {
  display: flex;
  margin-left: 10%;
  user-select: none;
}

.site-box-title > div {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid black;
}

.select-areaname {
  background-color: #48a2ff;
}

.noselectdefult {
  /* 禁止文本选中 */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  /* 禁止拖拽选中（针对图片或链接等） */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-drag: none; /* Safari */
}

.myradio {
  font-size: 28px;
  overflow-x: clip;
}

.myradio /deep/ .el-radio__label {
  font-size: 18px !important; /* 设置字体大小为 16 像素，可以根据需要调整 */
}

.myel-divider {
  margin: 5px 0 10px;
}
</style>
